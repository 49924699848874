import { logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getAnalytics } from './firebase-service';
import { namedLogger } from '../utils';

const logger = namedLogger('telemetry-service');

/*
  Abstraction layer around firebase analytics.
*/

// we want to init analytics early as we can to ensure
// the analytics instance has been initialized with required
// data to be able to track.
const initAnalytics = (): void => {
  // initialize and enable analytics
  const analytics = getAnalytics();
  // NOTE: i'm not sure this is even necessary; i think it's enabled by default.
  setAnalyticsCollectionEnabled(analytics, true);
};

const recordEvent = (event: string, params?: { [key: string]: any }): void => {
  try {
    const analytics = getAnalytics();
    logEvent(analytics, event, params);
  } catch (error: any) {
    logger.error('Error recording analytics event:', error);
  }
};

export { initAnalytics, recordEvent };
