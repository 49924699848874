import logo from './images/logo_512x256.png';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-b from-gray-500 to-gray-50">
      <div className="text-center m-6 mt-8 p-8 max-w-xl bg-white rounded-md space-y-5">
        <img src={logo} alt="Stanley's Baked Goods" />
        <h3 className="text-3xl font-bold">Coming Soon!</h3>
        <p>
          Our site isn't fully baked yet. It won't take long, we promise. Come back and visit us
          again.
        </p>
        <p>Thank you for your patience!</p>
        <p>
          In the meantime you can reach us at{' '}
          <a href="mailto:ryan@stanleysbakedgoods.ca">ryan@stanleysbakedgoods.ca</a>
        </p>
      </div>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
