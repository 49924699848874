import { Logger } from '../models';
import { LogLevel } from '../types';

const logLevelPriority: { [key: string]: number } = {
  silent: -1,
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
};

// default log level
let localLogLevel: LogLevel = 'debug';

// set production logging levels
if (process.env.NODE_ENV === 'production') {
  localLogLevel = 'error';
}

const isLogLevelEnabled = (level: LogLevel) => {
  const currentLevel = logLevelPriority[localLogLevel];
  return currentLevel >= 0 && logLevelPriority[level] >= currentLevel;
};

const consoleLogger = (identifier: string | null = null): Logger => {
  const withIdentifier = (msg: string): string => {
    return `[${identifier}]: ${msg}`;
  };

  return {
    debug: (message: string, ...optionalParams: any[]): void => {
      if (isLogLevelEnabled('debug')) {
        console.debug(withIdentifier(message), ...optionalParams);
      }
    },
    info: (message: string, ...optionalParams: any[]): void => {
      if (isLogLevelEnabled('info')) {
        console.info(withIdentifier(message), ...optionalParams);
      }
    },
    warn: (message: string, ...optionalParams: any[]): void => {
      if (isLogLevelEnabled('warn')) {
        console.warn(withIdentifier(message), ...optionalParams);
      }
    },
    error: (message: string, ...optionalParams: any[]): void => {
      if (isLogLevelEnabled('error')) {
        console.error(withIdentifier(message), ...optionalParams);
      }
    },
  };
};

export const namedLogger = (name: string): Logger => {
  return consoleLogger(name);
};
