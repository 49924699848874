import { initializeApp } from 'firebase/app';
import { getAnalytics as getFirebaseAnalytics, Analytics } from 'firebase/analytics';

/*
  Main point for configuring firebase services for the app.
*/

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// NOTE on OLD vs. NEW way below...
// wasn't sure that caching the instances was necessary so decided
// to just always let firebase decide what to return. the docs all
// show just grabbing from firebase directly but the examples aren't
// exactly very complex or real-world.

// OLD WAY (get instance from firebase and cache it)
// let analyticsClient: Analytics
// const getAnalytics = (): Analytics => {
//   if (!analyticsClient) {
//     analyticsClient = getFirebaseAnalytics(app)
//   }
//   return analyticsClient
// }
// NEW WAY (always get instance from firebase directly)
export const getAnalytics = (): Analytics => {
  return getFirebaseAnalytics(app);
};
